<template>
  <div class="sconProdList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickImport" v-if="sconForm.order_type === '3'">导入部件</el-button>
      <!--      <el-button type="danger" plain size="small" @click="deletePart" v-if="sconForm.order_type === '4'">删除</el-button>-->
      <!--      <el-button type="primary" v-show="$route.path.indexOf('add') === -1" size="small" @click="refresh">刷新</el-button>-->
      <el-button type="primary" size="small" @click="copyRow" v-if="sconForm.order_type === '3'">复制</el-button>
    </div>
    <el-table ref="SconPart" :data="sconForm.scon_part_list" :row-class-name="rowClassName" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_no'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].prod_no" disabled maxlength="30" placeholder="暂无我司货号" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="货号后缀" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_poststfix'">
            <el-input
              v-model="sconForm.scon_part_list[scope.$index].prod_poststfix"
              disabled
              maxlength="2"
              show-word-limit
              placeholder="暂无货号后缀"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="部件名称" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_partno'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].prod_partno" disabled placeholder="暂无部件名称" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_cust_no'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].prod_cust_no" disabled placeholder="暂无客户货号" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文名称" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_name'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].prod_name" disabled show-word-limit placeholder="暂无中文名称">
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(1, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <el-button slot="reference"
                    ><el-link type="info"><i class="el-icon-more"></i></el-link
                  ></el-button>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文规格" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_spec'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].prod_spec" disabled placeholder="暂无中文规格" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="成品描述" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_desc'">
            <el-input
              v-model="sconForm.scon_part_list[scope.$index].prod_desc"
              show-word-limit
              placeholder="暂无成品描述"
              :disabled="sconForm.scon_part_list[scope.$index].disabled"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="false"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <el-button slot="reference">...</el-button>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="部件单位" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_unit'">
            <el-select
              filterable
              v-model="sconForm.scon_part_list[scope.$index].prod_unit"
              placeholder="请选择外包装单位"
              :disabled="sconForm.scon_part_list[scope.$index].disabled"
            >
              <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="用量" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_use'">
            <el-input
              @change="sconPartNumChange(scope.$index)"
              v-model="sconForm.scon_part_list[scope.$index].prod_use"
              @blur="sconForm.scon_part_list[scope.$index].prod_use = helper.calcPrice2(sconForm.scon_part_list[scope.$index].prod_use, 2, 10000)"
              @input="sconForm.scon_part_list[scope.$index].prod_use = helper.keepTNum1(sconForm.scon_part_list[scope.$index].prod_use)"
              maxlength="9"
              show-word-limit
              placeholder="暂无用量"
              :disabled="sconForm.scon_part_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="预计数量" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_num'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].scon_part_num" disabled placeholder="暂无预计数量" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="实际数量" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_num_reality'">
            <el-input
              v-model="sconForm.scon_part_list[scope.$index].scon_part_num_reality"
              @change="realityOnApply(scope.$index)"
              @input="val => (scope.row.scon_part_num_reality = keep6Decimal(val))"
              show-word-limit
              placeholder="暂无实际数量"
              :disabled="sconForm.scon_part_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="已申购数量" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_applied'">
            <el-input v-model="sconForm.scon_part_list[scope.$index].scon_part_applied" disabled placeholder="暂无已申购数量" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="需申购数量" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_apply'">
            <el-input
              disabled
              @change="totalPriceChange(scope.$index, 0)"
              v-model="sconForm.scon_part_list[scope.$index].scon_part_apply"
              @input="sconForm.scon_part_list[scope.$index].scon_part_apply = helper.keepTNum(sconForm.scon_part_list[scope.$index].scon_part_apply)"
              maxlength="10"
              show-word-limit
              placeholder="暂无需申购数量"
            ></el-input>
            <!-- :disabled="!sconForm.scon_part_list[scope.$index].scon_part_num" -->
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="剩余申购数量" width="150">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.surplus_price'">-->
      <!--            <el-input-->
      <!--              disabled-->
      <!--              v-model="sconForm.scon_part_list[scope.$index].surplus_price"-->
      <!--              @input="-->
      <!--                sconForm.scon_part_list[scope.$index].scon_part_apply = helper.keepTNum(-->
      <!--                  sconForm.scon_part_list[scope.$index].scon_part_apply-->
      <!--                )-->
      <!--              "-->
      <!--              maxlength="10"-->
      <!--              show-word-limit-->
      <!--              placeholder="暂无需申购数量"-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="采购单价" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_price'">
            <el-input
              @change="totalPriceChange(scope.$index, 1)"
              v-model="sconForm.scon_part_list[scope.$index].prod_price"
              @input="sconForm.scon_part_list[scope.$index].prod_price = helper.keepTNum1(sconForm.scon_part_list[scope.$index].prod_price)"
              @blur="sconForm.scon_part_list[scope.$index].prod_price = helper.calcPrice(sconForm.scon_part_list[scope.$index].prod_price, 4, 10000)"
              maxlength="9"
              show-word-limit
              placeholder="暂无采购单价"
              :disabled="sconForm.scon_part_list[scope.$index].prod_price_disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购金额" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.total_price'">
            <el-input
              v-if="flagShow"
              v-model="sconForm.scon_part_list[scope.$index].total_price"
              disabled
              show-word-limit
              placeholder="暂无采购金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="供应商简称" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.supp_id'">
            <el-select
              filterable
              v-model="sconForm.scon_part_list[scope.$index].supp_id"
              placeholder="请选择供应商简称"
              :disabled="sconForm.scon_part_list[scope.$index].disabled"
            >
              <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="是否主商品" width="150">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_mainsup'">
            <!--            <el-input-->
            <!--              v-if="sconForm.scon_part_list[scope.$index].prod_mainsup === 1"-->
            <!--              v-model="value1"-->
            <!--              show-word-limit-->
            <!--              placeholder="暂无是否主厂"-->
            <!--            ></el-input>-->
            <!--            <el-input-->
            <!--              v-else-if="sconForm.scon_part_list[scope.$index].prod_mainsup === 0"-->
            <!--              v-model="value2"-->
            <!--              show-word-limit-->
            <!--              placeholder="暂无是否主厂"-->
            <!--            ></el-input>-->
            <el-radio v-model="sconForm.scon_part_list[scope.$index].prod_mainsup" :label="1" :disabled="sconForm.scon_part_list[scope.$index].disabled"
              >是</el-radio
            >
            <el-radio v-model="sconForm.scon_part_list[scope.$index].prod_mainsup" :label="0" :disabled="sconForm.scon_part_list[scope.$index].disabled"
              >否</el-radio
            >
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="采购合同状态" width="110" fixed="right">
        <template slot-scope="scope">
          <div style="text-align: center">
            <el-tag :type="buttonShipstat(sconForm.scon_part_list[scope.$index].create_status).type" size="mini">
              {{ buttonShipstat(sconForm.scon_part_list[scope.$index].create_status).name }}
            </el-tag>
          </div>
        </template>
      </el-table-column>-->
    </el-table>
    <el-dialog title="部件" :visible.sync="dialogVisible" width="70%">
      <PartImport ref="PartImport" @childConfirm="childConfirm" @childChanel="childChanel"></PartImport>
    </el-dialog>
  </div>
</template>

<script>
import PartImport from './PartImport.vue';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { sconAPI } from '@api/modules/sconOrder';
import { cloneDeep } from 'lodash';
import { keep6Decimal } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SconPartList',
  props: {
    sconForm: {
      type: Object,
      required: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    suppList: {
      type: Array,
      required: true
    },
    prodUnitList: {
      type: Array,
      required: true
    }
  },
  components: {
    PartImport,
    textEnlargement
  },
  data() {
    return {
      dialogVisible: false,
      selectionsList: [],
      // prodUnitList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keep6Decimal,
    initData() {
      // this.getProdRankList()
      // this.getSupp()
    },
    buttonShipstat(item) {
      if (!item) {
        return { name: '暂无状态', type: 'danger' };
      }
      if (item === 0) {
        return { name: '未生成', type: 'danger' };
      } else if (item === 1) {
        return { name: '部分生成', type: 'warning' };
      } else if (item === 2) {
        return { name: '已生成', type: 'success' };
      }
    },
    //需申购数量取实际数量-已申购数量
    realityOnApply(index) {
      // this.sconForm.scon_part_list[index].scon_part_apply =
      //   this.sconForm.scon_part_list[index].scon_part_num_reality - this.sconForm.scon_part_list[index].scon_part_applied;
      this.sconForm.scon_part_list[index].scon_part_num_reality = new BigNumber(this.sconForm.scon_part_list[index].scon_part_num_reality).toFixed(2, 0);
      this.sconForm.scon_part_list[index].scon_part_apply = new BigNumber(
        this.sconForm.scon_part_list[index].scon_part_num_reality - this.sconForm.scon_part_list[index].scon_part_applied
      ).toFixed(2, 0);
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      if (this.sconForm.scon_prod_list.length > 0) {
        this.dialogVisible = true;
        setTimeout(() => {
          this.$refs.PartImport.prodId = this.showFlagNumId;
          // this.$refs.PartImport.prodId = 10;
          this.$refs.PartImport.initData();
        }, 100);
      } else {
        this.$message.warning('请先导入成品');
      }
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      let idNum = null;
      let prodNum = 0;

      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        if (this.sconForm.scon_prod_list[i].prod_id === this.showFlagNumId) {
          idNum = this.sconForm.scon_prod_list[i].scon_prod_id;
          prodNum = this.sconForm.scon_prod_list[i].prod_num;
        }
      }

      for (let i = 0; i < val.length; i++) {
        val[i].scon_part_applied = null;
        val[i].disabled = false;
        val[i].scon_part_apply = null;
        val[i].scon_prod_id = idNum;
        val[i].prod_num = prodNum;
        val[i].prod_price = this.helper.haveFour(val[i].prod_price);
        val[i].scon_part_num = parseInt((Number(val[i].prod_use) * 100000 * Number(val[i].prod_num)) / 100000);
        val[i].scon_part_num_reality = val[i].scon_part_num;
        val[i].scon_part_applied = 0;
        val[i].surplus_price = 0;
        val[i].scon_part_apply = val[i].scon_part_num;
        val[i].total_price = this.helper.haveFour((Number(val[i].scon_part_apply) * 100000 * Number(val[i].prod_price)) / 100000);
      }
      //不同成品下可以导入相同的部件
      let partList = this.sconForm.scon_part_list.filter(item => item.scon_prod_id === prodNum);
      val = val.filter(item => partList.every(item1 => item.part_id !== item1.part_id));
      this.sconForm.scon_part_list = this.sconForm.scon_part_list.concat(val);
      this.dialogVisible = false;
    },
    //每一行的回调方法
    rowClassName(row) {
      if (row.row.scon_prod_id !== this.showFlagNumIdCopy) {
        row.row.selectflag = false;
        return 'showRow';
      } else {
        row.row.selectflag = true;
      }
    },
    // 删除每一行
    deletePart() {
      if (this.selectionsList.filter(x => x.selectflag).length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.sconForm.scon_part_list = this.sconForm.scon_part_list.filter(item =>
            this.selectionsList.filter(x => x.selectflag).every(item1 => item.scon_orderpart_id !== item1.scon_orderpart_id)
          );
        })
        .catch(err => console.error(err));
    },
    copyRow() {
      if (this.selectionsList.length === 0 || this.selectionsList.length > 1) return this.$message.warning('请选择一条数据!');
      let deepData = cloneDeep(this.selectionsList[0]);
      deepData.scon_part_id = null;
      this.sconForm.scon_part_list.unshift(deepData);
      this.$refs.SconPart.clearSelection();
    },
    // 计算预计数量
    sconPartNumChange(val) {
      // this.sconForm.scon_part_list[val].scon_part_num = parseInt(
      //   (Number(this.sconForm.scon_part_list[val].prod_use) * 100000 * Number(this.sconForm.scon_part_list[val].prod_num)) / 100000
      // );
      this.sconForm.scon_part_list[val].scon_part_num = new BigNumber(this.sconForm.scon_part_list[val].prod_use)
        .times(this.sconForm.scon_part_list[val].prod_num)
        .toFixed(2, 0); // 向上取整
      this.sconForm.scon_part_list[val].scon_part_num_reality = this.sconForm.scon_part_list[val].scon_part_num;
      //需申购数量 = 实际数量 - 已申购数量
      // this.sconForm.scon_part_list[val].scon_part_apply =
      //   this.sconForm.scon_part_list[val].scon_part_num_reality - this.sconForm.scon_part_list[val].scon_part_applied;
      this.sconForm.scon_part_list[val].scon_part_apply = new BigNumber(this.sconForm.scon_part_list[val].scon_part_num_reality)
        .minus(this.sconForm.scon_part_list[val].scon_part_applied)
        .toFixed(2, 0);
    },
    // 计算采购金额
    totalPriceChange(val, val1) {
      if (val1 === 0) {
        if (
          Number(this.sconForm.scon_part_list[val].scon_part_apply) + Number(this.sconForm.scon_part_list[val].scon_part_applied) >
          Number(this.sconForm.scon_part_list[val].scon_part_num)
        ) {
          this.sconForm.scon_part_list[val].scon_part_apply = parseInt(
            (Number(this.sconForm.scon_part_list[val].scon_part_num) * 100000 - Number(this.sconForm.scon_part_list[val].scon_part_applied) * 100000) / 100000
          );
          this.sconForm.scon_part_list[val].total_price = this.helper.haveFour(
            (Number(this.sconForm.scon_part_list[val].scon_part_apply) * 100000 * Number(this.sconForm.scon_part_list[val].prod_price)) / 100000
          );
          this.sconForm.scon_part_list[val].surplus_price = 0;
        } else {
          this.flagShow = false;
          let price = '';
          price = this.helper.haveFour(
            (Number(this.sconForm.scon_part_list[val].scon_part_apply) * 100000 * Number(this.sconForm.scon_part_list[val].prod_price)) / 100000
          );
          this.sconForm.scon_part_list[val].total_price = price;
          this.sconForm.scon_part_list[val].surplus_price =
            (Number(this.sconForm.scon_part_list[val].scon_part_num) * 100000 -
              Number(this.sconForm.scon_part_list[val].scon_part_applied) * 100000 -
              Number(this.sconForm.scon_part_list[val].scon_part_apply) * 100000) /
            100000;
          this.flagShow = true;
        }
      } else if (val1 === 1) {
        this.sconForm.scon_part_list[val].total_price = this.helper.haveFour(
          (Number(this.sconForm.scon_part_list[val].scon_part_num) * 100000 * Number(this.sconForm.scon_part_list[val].prod_price)) / 100000
        );
      }
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.sconForm.scon_part_list[val1].prod_desc;
      } else if (val === 1) {
        this.textEnlargementString.str = this.sconForm.scon_part_list[val1].prod_name;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.sconForm.scon_part_list[this.textEnlargementNum].prod_desc = val;
      } else if (this.textEnlargementFlag === 1) {
        this.sconForm.scon_part_list[this.textEnlargementNum].prod_name = val;
      }
    },
    refresh() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      sconAPI.getProduct({ sconId: props.form_id }).then(({ data }) => {
        this.childConfirm(data.list);
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
