<template>
  <div class="sconProdList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickImport" v-if="sconForm.order_type === '3'">导入材料</el-button>
      <!--      <el-button type="danger" plain size="small" @click="deleteMtrb" v-if="sconForm.order_type === '4'">删除</el-button>-->
    </div>
    <el-table :data="sconForm.scon_mtrb_list" border @selection-change="handleSelectionChange" :row-class-name="rowClassName">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="材料编号" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_no'">
            <el-input v-model="sconForm.scon_mtrb_list[scope.$index].mtrb_no" disabled maxlength="30" show-word-limit placeholder="暂无材料编号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="材料名称" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_name'">
            <el-input v-model="sconForm.scon_mtrb_list[scope.$index].mtrb_name" disabled maxlength="100" placeholder="暂无材料名称" show-word-limit>
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(1, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <el-button slot="reference">
                    <el-link type="info"><i class="el-icon-more"></i></el-link>
                  </el-button>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_spec'">
            <el-input v-model="sconForm.scon_mtrb_list[scope.$index].mtrb_spec" disabled show-word-limit placeholder="暂无规格">
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <el-button slot="reference">
                    <el-link type="info"><i class="el-icon-more"></i></el-link>
                  </el-button>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_unit'">
            <el-input v-model="sconForm.scon_mtrb_list[scope.$index].mtrb_unit" disabled maxlength="30" show-word-limit placeholder="暂无单位"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="耗量" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_use'">
            <el-input
              @change="prodMtrbPeruseChange(scope.$index)"
              v-model="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_use"
              @blur="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_use = helper.calcPriceT(sconForm.scon_mtrb_list[scope.$index].prod_mtrb_use, 2, 10000)"
              @input="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_use = helper.keepTNum1(sconForm.scon_mtrb_list[scope.$index].prod_mtrb_use)"
              maxlength="7"
              show-word-limit
              placeholder="暂无耗量"
              :disabled="sconForm.scon_mtrb_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="基数" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_per'">
            <el-input
              @change="prodMtrbPeruseChange(scope.$index)"
              v-model="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_per"
              @blur="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_per = helper.calcPrice2(sconForm.scon_mtrb_list[scope.$index].prod_mtrb_per, 2, 10000)"
              @input="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_per = helper.keepTNum1(sconForm.scon_mtrb_list[scope.$index].prod_mtrb_per)"
              maxlength="9"
              show-word-limit
              placeholder="暂无基数"
              :disabled="sconForm.scon_mtrb_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单耗比" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_peruse'">
            <el-input
              v-model="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_peruse"
              disabled
              maxlength="7"
              show-word-limit
              placeholder="暂无单耗比"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="预计数量" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_num_predict'">
            <el-input
              v-model="sconForm.scon_mtrb_list[scope.$index].scon_mtrb_num_predict"
              disabled
              maxlength="7"
              show-word-limit
              placeholder="暂无预计数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="实际数量" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_num'">
            <el-input
              v-model="sconForm.scon_mtrb_list[scope.$index].scon_mtrb_num"
              :disabled="sconForm.scon_mtrb_list[scope.$index].disabled"
              maxlength="7"
              @change="realityOnApply(scope.$index)"
              show-word-limit
              placeholder="暂无预计数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="已申购数量" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_applied'">
            <el-input
              v-model="sconForm.scon_mtrb_list[scope.$index].scon_mtrb_applied"
              disabled
              maxlength="7"
              show-word-limit
              placeholder="暂无已申购数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="需申购数量" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_apply'">
            <!--            @blur="
            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply = helper.calcPriceT(
            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply,
            2,
            10000
            )
            "
            @input="
            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply = helper.keepTNum(
            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply
            )
            "-->
            <el-input
              @change="sconMtrbApplyChange(scope.$index)"
              disabled
              v-model="sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply"
              maxlength="7"
              show-word-limit
              placeholder="暂无需申购数量"
            ></el-input>
            <!-- :disabled="!sconForm.scon_mtrb_list[scope.$index].scon_mtrb_num" -->
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="剩余申购数量" width="150">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.surplus_price'">-->
      <!--            &lt;!&ndash;            @blur="-->
      <!--            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply = helper.calcPriceT(-->
      <!--            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply,-->
      <!--            2,-->
      <!--            10000-->
      <!--            )-->
      <!--            "-->
      <!--            @input="-->
      <!--            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply = helper.keepTNum(-->
      <!--            sconForm.scon_mtrb_list[scope.$index].scon_mtrb_apply-->
      <!--            )-->
      <!--            "&ndash;&gt;-->
      <!--            <el-input-->
      <!--              disabled-->
      <!--              v-model="sconForm.scon_mtrb_list[scope.$index].surplus_price"-->
      <!--              maxlength="7"-->
      <!--              show-word-limit-->
      <!--              placeholder="暂无剩余申购数量"-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="采购单价" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_price'">
            <el-input
              @change="mtrbTotalPriceChange(scope.$index)"
              v-model="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_price"
              @blur="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_price = helper.calcPrice(sconForm.scon_mtrb_list[scope.$index].prod_mtrb_price, 4, 10000)"
              @input="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_price = helper.keepTNum1(sconForm.scon_mtrb_list[scope.$index].prod_mtrb_price)"
              maxlength="9"
              show-word-limit
              placeholder="暂无采购单价"
              :disabled="sconForm.scon_mtrb_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购金额(元)" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_total_price'">
            <el-input
              v-model="sconForm.scon_mtrb_list[scope.$index].mtrb_total_price"
              disabled
              maxlength="7"
              show-word-limit
              placeholder="暂无采购金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="供应商简称" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.supp_id'">
            <!-- <el-input  v-model="sconForm.scon_mtrb_list[scope.$index].supp_abbr" disabled  maxlength="7"  show-word-limit placeholder="暂无供应商简称"></el-input> -->
            <el-select
              filterable
              v-model="sconForm.scon_mtrb_list[scope.$index].supp_id"
              placeholder="请选择供应商简称"
              :disabled="sconForm.scon_mtrb_list[scope.$index].disabled"
            >
              <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="提供方式" width="150">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_offer'">
            <el-input
              v-if="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_offer === 1"
              v-model="value1"
              disabled
              show-word-limit
              placeholder="暂无提供方式厂"
            ></el-input>
            <el-input
              v-else-if="sconForm.scon_mtrb_list[scope.$index].prod_mtrb_offer === 2"
              v-model="value2"
              disabled
              show-word-limit
              placeholder="暂无提供方式厂"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="材料" :visible.sync="dialogVisible" width="70%">
      <MtrbImport ref="MtrbImport" @childConfirm="childConfirm" @childChanel="childChanel"></MtrbImport>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import MtrbImport from './MtrbImport.vue';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { BigNumber } from 'bignumber.js';
import { calcKeep4 } from '@assets/js/StringUtils';
import { cloneDeep } from 'lodash';

export default {
  name: 'SconMtrbList',
  props: {
    sconForm: {
      type: Object,
      required: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    transmitIndex: {
      type: Number,
      required: true
    },
    suppList: {
      type: Array,
      required: true
    }
  },
  components: {
    MtrbImport,
    textEnlargement
  },
  data() {
    return {
      dialogVisible: false,
      selectionsList: [],
      value1: '自供',
      value2: '客供',
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      // this.getSupp()
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      if (this.sconForm.scon_mtrb_list) {
        this.dialogVisible = true;
        setTimeout(() => {
          this.$refs.MtrbImport.prodId = this.showFlagNumId;
          // this.$refs.MtrbImport.prodId = 10;
          this.$refs.MtrbImport.initData();
        }, 100);
      } else {
        this.$message.warning('请先导入成品');
      }
    },
    // 获取供应商
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    //需申购数量取实际数量-已申购数量
    realityOnApply(index) {
      //过滤已申购数量null值
      if (!this.sconForm.scon_mtrb_list[index].scon_mtrb_applied) {
        this.sconForm.scon_mtrb_list[index].scon_mtrb_applied = 0;
      }
      if (this.sconForm.scon_mtrb_list[index].scon_mtrb_num && this.sconForm.scon_mtrb_list[index].scon_mtrb_num !== 0) {
        //计算需申购数量
        this.sconForm.scon_mtrb_list[index].scon_mtrb_apply = new BigNumber(this.sconForm.scon_mtrb_list[index].scon_mtrb_num)
          .minus(this.sconForm.scon_mtrb_list[index].scon_mtrb_applied)
          .toFixed(2, 0);
        this.mtrbTotalPriceChange(index);
      }
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      let temp = [];
      let idNum = null;
      let prodNum = 0;
      for (let i = 0; i < val.length; i++) {
        for (let j = 0; j < this.sconForm.scon_mtrb_list.length; j++) {
          if (val[i].mtrb_id === this.sconForm.scon_mtrb_list[j].mtrb_id && val[i].prod_id === this.sconForm.scon_mtrb_list[j].prod_id) {
            temp.push(i);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        val.splice(j, 1);
      }
      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        if (this.sconForm.scon_prod_list[i].prod_id === this.showFlagNumId) {
          idNum = this.sconForm.scon_prod_list[i].scon_prod_id;
          prodNum = this.sconForm.scon_prod_list[i].prod_num;
        }
      }
      for (let i = 0; i < val.length; i++) {
        val[i].disabled = false;
        val[i].scon_mtrb_applied = null;
        val[i].scon_mtrb_apply = null;
        val[i].mtrb_total_price = null;
        val[i].prod_mtrb_use = 1;
        val[i].prod_mtrb_per = 1;
        val[i].prod_mtrb_peruse = 1;
        val[i].scon_prod_id = idNum;
        val[i].prod_num = prodNum;
        val[i].prod_mtrb_price = this.helper.haveFour(val[i].prod_mtrb_price);
        val[i].scon_mtrb_num = this.helper.reservedDigits((Number(val[i].prod_mtrb_peruse) * 100000 * Number(val[i].prod_num)) / 100000);
        val[i].surplus_price = '0.00';
        val[i].scon_mtrb_apply = val[i].scon_mtrb_num;
        val[i].scon_mtrb_applied = '0.00';
        val[i].mtrb_total_price = this.helper.haveFour((Number(val[i].scon_mtrb_num) * 100000 * Number(val[i].prod_mtrb_price)) / 100000);
      }
      this.sconForm.scon_mtrb_list = this.sconForm.scon_mtrb_list.concat(val);
      this.dialogVisible = false;
    },
    //每一行的回调方法
    rowClassName(row) {
      if (row.row.scon_prod_id !== this.showFlagNumIdCopy) {
        row.row.selectflag = false;
        return 'showRow';
      } else {
        row.row.selectflag = true;
      }
    },
    // 删除每一行
    deleteMtrb() {
      if (this.selectionsList.filter(x => x.selectflag).length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let selest = cloneDeep(this.selectionsList.filter(x => x.selectflag));
          let temp = [];
          for (let i = 0; i < selest.length; i++) {
            for (let j = 0; j < this.sconForm.scon_mtrb_list.length; j++) {
              if (selest[i].mtrb_id === this.sconForm.scon_mtrb_list[j].mtrb_id && selest[i].prod_id === this.sconForm.scon_mtrb_list[j].prod_id) {
                temp.push(j);
              }
            }
          }
          temp = temp.sort((a, b) => {
            return b - a;
          });
          for (let j of temp) {
            this.sconForm.scon_mtrb_list.splice(j, 1);
          }
        })
        .catch(err => console.error(err));
    },
    // 计算采购金额
    mtrbTotalPriceChange(val) {
      this.sconForm.scon_mtrb_list[val].mtrb_total_price = this.helper.haveFour(
        (Number(this.sconForm.scon_mtrb_list[val].scon_mtrb_num) * 100000 * Number(this.sconForm.scon_mtrb_list[val].prod_mtrb_price)) / 100000
      );
    },
    // 计算耗比
    prodMtrbPeruseChange(val) {
      let { prod_mtrb_use, prod_mtrb_per } = this.sconForm.scon_mtrb_list[val];
      this.sconForm.scon_mtrb_list[val].scon_mtrb_num_predict = new BigNumber(this.sconForm.scon_prod_list[this.transmitIndex].prod_num)
        .div(prod_mtrb_per)
        .times(prod_mtrb_use)
        .toFixed(2, 0);
      //实际数量
      this.sconForm.scon_mtrb_list[val].prod_mtrb_peruse = calcKeep4(new BigNumber(prod_mtrb_use).div(prod_mtrb_per).toFixed(2, 0));
      this.sconForm.scon_mtrb_list[val].scon_mtrb_num = new BigNumber(this.sconForm.scon_prod_list[this.transmitIndex].prod_num)
        .div(prod_mtrb_per)
        .times(prod_mtrb_use)
        .toFixed(2, 0);

      this.mtrbTotalPriceChange(val);
      this.sconMtrbApplyChange(val);
    },
    // 需申购数量变化
    sconMtrbApplyChange(val) {
      let { scon_mtrb_apply, scon_mtrb_applied, scon_mtrb_num } = this.sconForm.scon_mtrb_list[val];
      if (scon_mtrb_apply === null) {
        scon_mtrb_apply = 0;
      }
      if (scon_mtrb_applied === null) {
        scon_mtrb_applied = 0;
      }
      this.sconForm.scon_mtrb_list[val].scon_mtrb_apply = new BigNumber(scon_mtrb_num).minus(scon_mtrb_applied).toFixed(2, 0);
      // if (Number(scon_mtrb_apply) + Number(scon_mtrb_applied) > Number(scon_mtrb_num)) {
      //   this.sconForm.scon_mtrb_list[val].surplus_price = '0';
      // } else {
      //   this.sconForm.scon_mtrb_list[val].surplus_price = new BigNumber(scon_mtrb_num ?? 0)
      //     .minus(scon_mtrb_applied ?? 0)
      //     .minus(scon_mtrb_apply ?? 0)
      //     .toFixed(0, 0);
      // }
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.sconForm.scon_mtrb_list[val1].mtrb_spec;
      } else if (val === 1) {
        this.textEnlargementString.str = this.sconForm.scon_mtrb_list[val1].mtrb_name;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.sconForm.scon_mtrb_list[this.textEnlargementNum].mtrb_spec = val;
      } else if (this.textEnlargementFlag === 1) {
        this.sconForm.scon_mtrb_list[this.textEnlargementNum].mtrb_name = val;
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
