<template>
  <div class="sconClauInfo">
    <el-row>
      <el-col :md="24">
        <el-form-item label="公司抬头" prop="cptt_aname_en" :rules="{ required: true, message: ' ' }">
          <!--<el-input v-model="sconForm.cptt_aname_en" maxlength="100" show-word-limit placeholder="请输入公司抬头"> </el-input>-->
          <el-select
            @change="val => (sconForm.cptt_aid = cpttList.find(({ cptt_name }) => cptt_name === val).cptt_id)"
            filterable
            v-model="sconForm.cptt_aname_en"
            placeholder="请选择公司抬头"
            :disabled="sconDisabled.cptt_aname_en"
          >
            <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_name"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="收款抬头" prop="cptt_bname_en" :rules="{ required: true, message: ' ' }">
          <!--<el-input v-model="sconForm.cptt_bname_en" maxlength="100" show-word-limit placeholder="暂无收款抬头"> </el-input>-->
          <el-select
            @change="
              val => {
                let { cptt_id, cptt_bank_list: list } = cpttList.find(({ cptt_name_en }) => cptt_name_en === val);
                sconForm.cptt_bid = cptt_id;
                cptt_bank_list = list;
              }
            "
            filterable
            v-model="sconForm.cptt_bname_en"
            placeholder="请选择收款抬头"
            :disabled="sconDisabled.cptt_bname_en"
          >
            <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name_en" :value="item.cptt_name_en"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="银行名称" prop="cptt_bank_name">
          <el-select filterable v-model="sconForm.cptt_bank_name" @change="cpttBankChange" :disabled="sconDisabled.cptt_bank_name">
            <el-option v-for="(item, index) in cptt_bank_list" :key="index" :value="item.cptt_bank_name" :label="item.cptt_bank_name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="银行信息" prop="cptt_bank_account">
          <el-input
            v-model="sconForm.cptt_bank_account"
            show-word-limit
            type="textarea"
            placeholder="暂无银行信息"
            :disabled="sconDisabled.cptt_bank_account"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="8">
        <el-form-item label="货代名称" prop="inst_abbr">
          <el-input v-model="sconForm.inst_abbr" disabled placeholder="暂无机构名称">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="dialogInstVisible = true" :disabled="isShow">双击选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="16">
        <el-form-item label="货代英文名" prop="inst_ename">
          <el-input v-model="sconForm.inst_ename" disabled placeholder="暂无货代英文名"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="唛头" prop="cust_shipmark">
          <el-input v-model="sconForm.cust_shipmark" show-word-limit type="textarea" placeholder="暂无唛头" :disabled="sconDisabled.cust_shipmark"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <!-- <el-form-item label="" prop="clau_content" > -->
        <div style="display: flex">
          <div style="width: 120px; textalign: right; paddingright: 12px">
            <div>条款内容</div>
            <el-link class="vg_pointer" type="primary" @click="dialogClauVisible = true" :disabled="sconDisabled.clau_content">选择</el-link>
          </div>
          <el-input v-model="sconForm.clau_content" type="textarea" placeholder="暂无条款内容" :disabled="sconDisabled.clau_content"></el-input>
        </div>
        <!-- </el-form-item> -->
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <el-form-item label="备注" prop="scon_remark">
          <el-input
            v-model="sconForm.scon_remark"
            maxlength="255"
            clearable
            show-word-limit
            type="textarea"
            placeholder="暂无备注"
            :disabled="sconDisabled.scon_remark"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-dialog title="条款内容" :visible.sync="dialogClauVisible" width="70%">
      <ImportClau @importClauChange="importClauChange" />
    </el-dialog>
    <el-dialog title="机构信息" :visible.sync="dialogInstVisible" width="70%">
      <ImportInst @importInstChange="importInstChange" />
    </el-dialog>
  </div>
</template>

<script>
import { get } from '@api/request';
import ImportClau from './ImportClau.vue';
import ImportInst from './ImportInst.vue';
import { cpttAPI } from '../../../../../api/modules/comptitle';

export default {
  name: 'SconCaluInfo',
  components: {
    ImportClau,
    ImportInst
  },
  props: {
    sconForm: Object,
    isShow: Boolean,
    sconDisabled: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogClauVisible: false,
      dialogInstVisible: false,
      cpttList: [],
      cptt_bank_list: []
    };
  },
  created() {
    this.getCptt();
  },
  methods: {
    importClauChange(row) {
      this.dialogClauVisible = false;
      this.$emit('sconClauChange', row);
    },
    importInstChange(row) {
      this.dialogInstVisible = false;
      this.$emit('sconClauChange2', row);
    },
    // 获取供应商
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    cpttBankChange(val) {
      for (let i = 0; i < this.cptt_bank_list.length; i++) {
        if (this.cptt_bank_list[i].cptt_bank_name === val) {
          this.sconForm.cptt_bank_account = this.cptt_bank_list[i].cptt_bank_account;
        }
      }
    }
  }
};
</script>

<style></style>
