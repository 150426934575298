<template>
  <div class="importCust">
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" :row-key="getRowKey" border v-loading="loading" class="vg_pointer" @cell-dblclick="cellDblClick">
          <el-table-column type="index" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="条款部门" prop="clau_dept_name"></el-table-column>
          <el-table-column label="条款名称" prop="clau_name"></el-table-column>
          <el-table-column label="条款内容" prop="clau_content"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get, post } from '@api/request';
import { clauAPI } from '@/api/modules/clau';
export default {
  name: 'importCust',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      total: 0,
      tableData: [],
      loading: false
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getClau();
    },

    getClau() {
      this.loading = true;
      get(clauAPI.getAllClausV1, {
        page_no: this.searchForm.page_no
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.loading = false;
            return;
          }
          this.$message.error(res.data.msg);
          this.loading = false;
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //弹框 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getClau();
    },
    getRowKey(row) {
      return row.clau_no;
    },

    cellDblClick(row) {
      this.$emit('importClauChange', row);
    }
  }
};
</script>

<style></style>
