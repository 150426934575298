<template>
  <div class="sconPartList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickImport" v-if="sconForm.order_type === '3'">导入成品</el-button>
      <el-button type="danger" plain size="small" @click="deleteProd" v-if="sconForm.order_type === '4'">删除</el-button>
      <!--      <el-button type="primary" size="small" @click="copySconProd">复制</el-button>-->
      <el-button class="tb-btn" size="small" @click="moveUp" v-if="sconForm.order_type === '3'">上移</el-button>
      <el-button class="tb-btn" size="small" @click="moveDown" v-if="sconForm.order_type === '3'">下移</el-button>
      <!--      <el-button type="primary" size="small" @click="podrSync">同步成品</el-button>-->
    </div>
    <el-table
      show-summary
      :data="sconForm.scon_prod_list"
      border
      ref="prodTabRef"
      :row-key="getRowKeys"
      :header-cell-class-name="cellClassName"
      @header-click="headerClickHandel('prodTabRef', $event)"
      @selection-change="handleSelectionChange"
      class="vg_cursor_hander"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      @row-click="prodNoChaange"
      :summary-method="getSummaries"
    >
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column :fixed="fixed" width="1" class="vd_hidden"></el-table-column>
      <el-table-column width="150" label="我司货号" prop="prod_no">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_no'">
            <el-input v-model="sconForm.scon_prod_list[scope.$index].prod_no" disabled maxlength="30" show-word-limit placeholder="暂无我司货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="150" label="新我司货号" prop="new_prod_no">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.new_prod_no'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].new_prod_no"
              maxlength="30"
              show-word-limit
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
              placeholder="暂无新我司货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="货号后缀" width="150" prop="prod_poststfix">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_poststfix'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_poststfix"
              disabled
              maxlength="2"
              show-word-limit
              placeholder="暂无货号后缀"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="150" label="客户货号" prop="prod_cust_no">
        <template slot="header">
          <span>客户货号</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_cust_no'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_cust_no"
              maxlength="30"
              show-word-limit
              placeholder="暂无客户货号"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="200" label="英文名称" prop="prod_ename">
        <template slot="header">
          <span>英文名称</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_ename'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_ename"
              show-word-limit
              placeholder="暂无英文名称"
              maxlength="100"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(1, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="false"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="200" label="英文规格" show-overflow-tooltip prop="prod_espec">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_espec'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_espec"
              show-word-limit
              placeholder="暂无英文规格"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="false"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="成品单位" width="150" prop="prod_unit">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_unit'">
            <el-select
              filterable
              v-model="sconForm.scon_prod_list[scope.$index].prod_unit"
              placeholder="请选择成品单位"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            >
              <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="出运状态" width="150" prop="prod_shipstat">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_shipstat'">
            <el-input
              v-if="sconForm.scon_prod_list[scope.$index].prod_shipstat === 0"
              disabled
              v-model="prodShipstatValue0"
              show-word-limit
              placeholder="暂无"
            ></el-input>
            <el-input
              v-if="sconForm.scon_prod_list[scope.$index].prod_shipstat === 1"
              disabled
              v-model="prodShipstatValue1"
              show-word-limit
              placeholder="暂无"
            ></el-input>
            <el-input
              v-if="sconForm.scon_prod_list[scope.$index].prod_shipstat === 2"
              disabled
              v-model="prodShipstatValue2"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="成品数量" width="150" prop="prod_num">
        <template #header>
          <span class="vg_deep_red">成品数量</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            label-width="0"
            label="成品数量"
            :prop="'scon_prod_list.' + scope.$index + '.prod_num'"
            :rules="[{ required: true, message: ' ', trigger: 'blur' }]"
          >
            <template slot="label">
              <span style="display: none"></span>
            </template>
            <el-input
              @change="prodTotalPrice(scope.$index)"
              v-model="sconForm.scon_prod_list[scope.$index].prod_num"
              @input="sconForm.scon_prod_list[scope.$index].prod_num = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_num)"
              maxlength="10"
              show-word-limit
              placeholder="暂无成品数量"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="150" prop="prod_usd">
        <template #header>
          <span>销售价</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_usd'">
            <el-input
              @change="prodTotalPrice(scope.$index)"
              v-model="sconForm.scon_prod_list[scope.$index].prod_usd"
              @input="sconForm.scon_prod_list[scope.$index].prod_usd = helper.keepTNum1(sconForm.scon_prod_list[scope.$index].prod_usd)"
              @blur="sconForm.scon_prod_list[scope.$index].prod_usd = helper.calcPrice(sconForm.scon_prod_list[scope.$index].prod_usd, 4, 10000)"
              maxlength="9"
              show-word-limit
              placeholder="暂无销售价"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额(美元)" width="150" prop="prod_total_price">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_total_price'">
            <el-input v-model="sconForm.scon_prod_list[scope.$index].prod_total_price" disabled show-word-limit placeholder="暂无金额"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="内包装数量" width="150" prop="prod_qpb">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_qpb'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_qpb"
              @input="sconForm.scon_prod_list[scope.$index].prod_qpb = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_qpb)"
              maxlength="10"
              show-word-limit
              placeholder="暂无内包装数量"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外包装数量" width="150" prop="prod_qpc">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_qpc'">
            <el-input
              @change="prodBoxNum(scope.$index)"
              v-model="sconForm.scon_prod_list[scope.$index].prod_qpc"
              @input="sconForm.scon_prod_list[scope.$index].prod_qpc = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_qpc)"
              maxlength="10"
              show-word-limit
              placeholder="暂无外包装数量"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外包装单位" width="150" prop="prod_box_unit">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_box_unit'">
            <el-select
              filterable
              v-model="sconForm.scon_prod_list[scope.$index].prod_box_unit"
              placeholder="请选择外包装单位"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            >
              <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="箱数" width="150" prop="prod_box_num">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_box_num'">
            <el-input v-model="sconForm.scon_prod_list[scope.$index].prod_box_num" disabled show-word-limit placeholder="暂无箱数"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="长(CM)" width="150" prop="prod_boxl">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_boxl'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_boxl"
              show-word-limit
              placeholder="暂无长"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="宽(CM)" width="150" prop="prod_boxw">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_boxw'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_boxw"
              show-word-limit
              placeholder="暂无宽"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="高(CM)" width="150" prop="prod_boxh">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_boxh'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_boxh"
              show-word-limit
              placeholder="暂无高"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱毛重" width="150" prop="prod_gweight">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_gweight'">
            <el-input
              @change="totalGrossWeightChange(scope.$index)"
              v-model="sconForm.scon_prod_list[scope.$index].prod_gweight"
              maxlength="7"
              @blur="sconForm.scon_prod_list[scope.$index].prod_gweight = helper.calcPriceT(sconForm.scon_prod_list[scope.$index].prod_gweight, 2, 10000)"
              @input="sconForm.scon_prod_list[scope.$index].prod_gweight = helper.keepTNum2(sconForm.scon_prod_list[scope.$index].prod_gweight)"
              show-word-limit
              placeholder="暂无外箱毛重"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总毛重" width="150" prop="total_gross_weight">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.total_gross_weight'">
            <el-input v-model="sconForm.scon_prod_list[scope.$index].total_gross_weight" disabled show-word-limit placeholder="暂无总毛重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱净重" width="150" prop="prod_nweight">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_nweight'">
            <el-input
              @change="totalNetWeight(scope.$index)"
              v-model="sconForm.scon_prod_list[scope.$index].prod_nweight"
              @blur="sconForm.scon_prod_list[scope.$index].prod_nweight = helper.calcPriceT(sconForm.scon_prod_list[scope.$index].prod_nweight, 2, 10000)"
              @input="sconForm.scon_prod_list[scope.$index].prod_nweight = helper.keepTNum2(sconForm.scon_prod_list[scope.$index].prod_nweight)"
              maxlength="7"
              show-word-limit
              placeholder="暂无总毛重"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总净重" width="150" prop="total_net_weight">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.total_net_weight'">
            <el-input v-model="sconForm.scon_prod_list[scope.$index].total_net_weight" disabled show-word-limit placeholder="暂无总净重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱体积" width="150" prop="prod_vol">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_vol'">
            <el-input
              @change="totalVolumeChange(scope.$index)"
              @blur="sconForm.scon_prod_list[scope.$index].prod_vol = helper.calcPriceT(sconForm.scon_prod_list[scope.$index].prod_vol, 2, 10000)"
              @input="sconForm.scon_prod_list[scope.$index].prod_vol = keep2Decimal(sconForm.scon_prod_list[scope.$index].prod_vol)"
              v-model="sconForm.scon_prod_list[scope.$index].prod_vol"
              maxlength="9"
              show-word-limit
              placeholder="暂无外箱体积"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总体积" width="150" prop="total_volume">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.total_volume'">
            <el-input v-model="sconForm.scon_prod_list[scope.$index].total_volume" disabled show-word-limit placeholder="暂无总体积"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品条形码" width="200" prop="prod_bar">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_bar'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_bar"
              @input="sconForm.scon_prod_list[scope.$index].prod_bar = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_bar)"
              maxlength="20"
              show-word-limit
              placeholder="暂无产品条形码"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="内包装条形码" width="200" prop="prod_inbar">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_inbar'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_inbar"
              @input="sconForm.scon_prod_list[scope.$index].prod_inbar = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_inbar)"
              maxlength="20"
              show-word-limit
              placeholder="暂无内包装条形码"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="外箱条形码" width="200" prop="prod_outbar">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_outbar'">
            <el-input
              v-model="sconForm.scon_prod_list[scope.$index].prod_outbar"
              @input="sconForm.scon_prod_list[scope.$index].prod_outbar = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_outbar)"
              maxlength="20"
              show-word-limit
              placeholder="暂无外箱条形码"
              :disabled="sconForm.scon_prod_list[scope.$index].disabled"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="外销经办人" width="200" prop="cust_stff_id">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.cust_stff_id'">-->
      <!--            <el-select-->
      <!--              filterable-->
      <!--              v-model="sconForm.scon_prod_list[scope.$index].cust_stff_id"-->
      <!--              placeholder="请选择外销经办人"-->
      <!--              size="small"-->
      <!--              clearable-->
      <!--            >-->
      <!--              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>-->
      <!--            </el-select>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="美金报价" width="200" prop="prod_usd_price">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.prod_usd_price'">
            <el-input
              disabled
              v-model="sconForm.scon_prod_list[scope.$index].prod_usd_price"
              @input="sconForm.scon_prod_list[scope.$index].prod_usd_price = helper.keepTNum(sconForm.scon_prod_list[scope.$index].prod_usd_price)"
              maxlength="20"
              show-word-limit
              placeholder="暂无美金报价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="对应外销" width="200" prop="scon_stff_id">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'scon_prod_list.' + scope.$index + '.scon_stff_id'">
            <el-select filterable disabled v-model="sconForm.scon_prod_list[scope.$index].scon_stff_id" placeholder="暂无对应外销" size="small" clearable>
              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="成品信息" :visible.sync="dialogVisible" width="70%">
      <ProdImport @childConfirm="childConfirm" @childChanel="childChanel"></ProdImport>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { optnAPI } from '@api/modules/optn';
import ProdImport from './ProdImport.vue';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { keep2Decimal } from '@assets/js/regExUtil';
import { sconAPI } from '@api/modules/sconOrder';
import { cloneDeep } from 'lodash';
import { getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';
export default {
  name: 'sconPartList',
  mixins: [myMixin],
  props: {
    sconForm: {
      type: Object,
      required: true
    },
    addFlagId: {
      type: Number,
      required: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    prodUnitList: {
      type: Array,
      required: true
    }
  },
  components: {
    ProdImport,
    textEnlargement
  },
  data() {
    return {
      dialogVisible: false,
      selectionsList: [],
      stffUserList: [],
      // prodUnitList: [],
      getIndex: '',
      prodShipstatValue0: '未出运',
      prodShipstatValue1: '已出运',
      prodShipstatValue2: '部分出运',
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    initData() {
      this.getStffUser();
      // this.getProdRankList()
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let values = data.map(item => {
          const num = Number(item[column.property]);
          return isNaN(num) ? 0 : num;
        });
        if (['prod_box_num', 'total_gross_weight', 'total_net_weight', 'total_volume', 'prod_num', 'prod_total_price'].includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(4);
        }
      });
      return sums;
    },
    // 获取单位
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = Array.from(res.data.data, ({ stff_id, stff_name }) => {
              return { stff_id: stff_id, stff_name: stff_name };
            });
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      this.dialogVisible = true;
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.sconForm.scon_prod_list.every(item1 => item.prod_id !== item1.prod_id));
      let temp = [];
      let temp1 = [];
      let idNum = this.addFlagId;
      for (let i = 0; i < val.length; i++) {
        val[i].scon_orderprod_id = null;
        val[i].disabled = false;
        val[i].prod_num = null;
        val[i].prod_total_price = null;
        // val[i].prod_box_unit = 'CTNS';
        val[i].prod_box_num = null;
        val[i].total_gross_weight = null;
        val[i].total_net_weight = null;
        val[i].total_volume = null;
        val[i].cust_stff_id = null;
        val[i].prod_shipstat = 0;
        val[i].prod_usd = this.helper.haveFour(val[i].prod_usd);
        val[i].scon_prod_id = idNum;
        if (this.sconForm.cust_stff_id) {
          val[i].cust_stff_id = this.sconForm.cust_stff_id;
        }
        if (val[i].prod_part_list) {
          let list = JSON.parse(JSON.stringify(val[i].prod_part_list));
          for (let j = 0; j < list.length; j++) {
            list[j].scon_prod_id = idNum;
          }
          temp = temp.concat(list);
        }
        if (val[i].prod_mtrb_list) {
          let list1 = JSON.parse(JSON.stringify(val[i].prod_mtrb_list));
          for (let j = 0; j < list1.length; j++) {
            list1[j].scon_prod_id = idNum;
          }
          temp1 = temp1.concat(list1);
        }
        idNum--;
      }
      for (let i = 0; i < temp.length; i++) {
        temp[i].scon_orderpart_id = null;
        temp[i].disabled = false;
        temp[i].scon_part_num = null;
        temp[i].prod_num = 0;
        temp[i].scon_part_applied = null;
        temp[i].scon_part_apply = null;
        temp[i].surplus_price = null;
        if (temp[i].prod_price) {
          temp[i].prod_price = this.helper.haveFour(temp[i].prod_price);
        } else {
          temp[i].prod_price = '0.0000';
        }
      }
      for (let i = 0; i < temp1.length; i++) {
        temp1[i].scon_ordermtrb_id = null;
        temp1[i].disabled = false;
        temp1[i].scon_mtrb_num = null;
        temp1[i].scon_mtrb_applied = null;
        temp1[i].prod_num = 0;
        temp1[i].scon_mtrb_apply = null;
        // temp1[i].prod_mtrb_use = 1;
        // temp1[i].prod_mtrb_per = 1;
        temp1[i].mtrb_total_price = null;
        temp1[i].prod_mtrb_price = this.helper.haveFour(temp1[i].prod_mtrb_price);
      }
      this.sconForm.scon_prod_list = this.sconForm.scon_prod_list.concat(val);
      this.sconForm.scon_part_list = this.sconForm.scon_part_list.concat(temp);
      this.sconForm.scon_mtrb_list = this.sconForm.scon_mtrb_list.concat(temp1);
      this.getIndex = 0;
      this.$emit('confirmProdId', this.sconForm.scon_prod_list[0].prod_id);
      this.$emit('confirmProdIdCopy', this.sconForm.scon_prod_list[0].scon_prod_id);
      this.$emit('addFlagIdChange', idNum);
      this.dialogVisible = false;
    },
    // 删除
    deleteProd() {
      if (this.selectionsList.length === 0) return this.$message.warning('请选择数据!');
      let msg = '';
      this.selectionsList.forEach(item => {
        let list = this.sconForm.scon_part_list.filter(x => x.scon_prod_id === item.scon_prod_id);
        let num = objectArrayReduce(list, 'scon_part_applied', 2);
        let list2 = this.sconForm.scon_mtrb_list.filter(x => x.scon_prod_id === item.scon_prod_id);
        let num2 = objectArrayReduce(list2, 'scon_mtrb_applied', 2);
        if (Number(num) > 0 || Number(num2) > 0) {
          msg += item.prod_no + ',';
        }
      });
      if (msg.length > 0) {
        this.$message.warning('删除失败！我司货号：' + msg + '下有已生成的采购合同！');
        return;
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let temp = [];
          let temp1 = [];
          let temp2 = [];
          for (let i = 0; i < this.selectionsList.length; i++) {
            temp.push(this.selectionsList[i].prod_id);
          }
          this.sconForm.scon_prod_list = this.sconForm.scon_prod_list.filter(item => this.selectionsList.every(item1 => item.prod_id !== item1.prod_id));
          for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < this.sconForm.scon_part_list.length; j++) {
              if (this.sconForm.scon_part_list[j].prod_id === temp[i]) {
                temp1.push(j);
              }
            }
            for (let j = 0; j < this.sconForm.scon_mtrb_list.length; j++) {
              if (this.sconForm.scon_mtrb_list[j].prod_id === temp[i]) {
                temp2.push(j);
              }
            }
          }
          temp1 = temp1.sort((a, b) => {
            return b - a;
          });
          for (let j of temp1) {
            this.sconForm.scon_part_list.splice(j, 1);
          }
          temp2 = temp2.sort((a, b) => {
            return b - a;
          });
          for (let j of temp2) {
            this.sconForm.scon_mtrb_list.splice(j, 1);
          }
          if (this.sconForm.scon_prod_list.length > 0) {
            let flag = true;
            for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
              if (this.sconForm.scon_prod_list[i].prod_id === this.showFlagNumId) {
                flag = false;
              }
            }
            if (flag) {
              this.getIndex = this.sconForm.scon_prod_list.length - 1;
              this.$emit('confirmProdId', this.sconForm.scon_prod_list[this.sconForm.scon_prod_list.length - 1].prod_id);
              this.$emit('confirmProdIdCopy', this.sconForm.scon_prod_list[this.sconForm.scon_prod_list.length - 1].scon_prod_id);
            }
          }
        })
        .catch(err => console.error(err));
    },
    // 单击每一行更换数据
    prodNoChaange(val, event, column) {
      this.getIndex = val.index;
      this.$emit('confirmProdId', val.prod_id);
      this.$emit('confirmProdIdCopy', val.scon_prod_id);
      this.$emit('prodCurrentIndex', val.index);
    },
    selectedstyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          'background-color': '#e1e1e1'
        };
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 计算金额
    prodTotalPrice(ind) {
      let calcNum = new BigNumber(this.sconForm.scon_prod_list[ind].prod_usd);
      calcNum = calcNum.times(this.sconForm.scon_prod_list[ind].prod_num).toNumber();
      this.sconForm.scon_prod_list[ind].prod_total_price = this.helper.haveFour(calcNum);
      this.prodBoxNum(ind);
      let prodId = this.sconForm.scon_prod_list[ind].prod_id;
      for (let i = 0; i < this.sconForm.scon_part_list.length; i++) {
        if (prodId === this.sconForm.scon_part_list[i].prod_id) {
          console.log(this.sconForm.scon_prod_list[ind].prod_num);
          this.sconForm.scon_part_list[i].prod_num = this.sconForm.scon_prod_list[ind].prod_num;
          // this.sconForm.scon_part_list[i].scon_part_num = parseInt(
          //   (Number(this.sconForm.scon_part_list[i].prod_use) * 100000 * Number(this.sconForm.scon_part_list[i].prod_num)) / 100000
          // );
          //计算预计数量
          this.sconForm.scon_part_list[i].scon_part_num = new BigNumber(
            (Number(this.sconForm.scon_part_list[i].prod_use) * 100000 * Number(this.sconForm.scon_part_list[i].prod_num)) / 100000
          ).toFixed(2, 0);
          //计算实际数量
          this.sconForm.scon_part_list[i].scon_part_num_reality = this.sconForm.scon_part_list[i].scon_part_num;
          //计算需申购数量
          this.sconForm.scon_part_list[i].scon_part_apply = new BigNumber(
            this.sconForm.scon_part_list[i].scon_part_num_reality - this.sconForm.scon_part_list[i].scon_part_applied
          ).toFixed(2, 0);
          // this.sconForm.scon_part_list[i].scon_part_num_reality = parseInt(this.sconForm.scon_part_list[i].scon_part_num);
          // this.sconForm.scon_part_list[i].scon_part_apply = parseInt(
          //   (Number(this.sconForm.scon_part_list[i].scon_part_num) * 100000 -
          //     Number(this.sconForm.scon_part_list[i].scon_part_applied) * 100000) /
          //     100000
          // );
          this.sconForm.scon_part_list[i].total_price = this.helper.haveFour(
            (Number(this.sconForm.scon_part_list[i].scon_part_apply) * 100000 * Number(this.sconForm.scon_part_list[i].prod_price)) / 100000
          );
          this.sconForm.scon_part_list[i].surplus_price =
            (Number(this.sconForm.scon_part_list[i].scon_part_num) * 100000 -
              Number(this.sconForm.scon_part_list[i].scon_part_applied) * 100000 -
              Number(this.sconForm.scon_part_list[i].scon_part_apply) * 100000) /
            100000;
        }
      }
      for (let i = 0; i < this.sconForm.scon_mtrb_list.length; i++) {
        if (prodId === this.sconForm.scon_mtrb_list[i].prod_id) {
          this.$emit('changeMtrbList', i);
          /*this.sconForm.scon_mtrb_list[i].prod_num = this.sconForm.scon_prod_list[ind].prod_num;
          this.sconForm.scon_mtrb_list[i].scon_mtrb_num = this.helper.reservedDigits(
            (Number(this.sconForm.scon_mtrb_list[i].prod_mtrb_peruse) * 100000 * Number(this.sconForm.scon_mtrb_list[i].prod_num)) / 100000
          );
          this.sconForm.scon_mtrb_list[i].scon_mtrb_apply = this.helper.reservedDigits(
            Number(this.sconForm.scon_mtrb_list[i].scon_mtrb_num) - Number(this.sconForm.scon_mtrb_list[i].scon_mtrb_applied)
          );
          this.sconForm.scon_mtrb_list[i].mtrb_total_price = this.helper.haveFour(
            (Number(this.sconForm.scon_mtrb_list[i].scon_mtrb_num) * 100000 * Number(this.sconForm.scon_mtrb_list[i].prod_mtrb_price)) /
              100000
          );
          this.sconForm.scon_mtrb_list[i].surplus_price = this.helper.reservedDigits(
            (Number(this.sconForm.scon_mtrb_list[i].scon_mtrb_num) * 100000 -
              Number(this.sconForm.scon_mtrb_list[i].scon_mtrb_applied) * 100000 -
              Number(this.sconForm.scon_mtrb_list[i].scon_mtrb_apply) * 100000) /
              100000
          );*/
        }
      }
    },
    // 上移
    moveUp() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
        this.$refs.prodTabRef.clearSelection();
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
        this.$refs.prodTabRef.clearSelection();
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.index === 0) {
          this.$message.warning('已经是第一条');
          this.$refs.prodTabRef.clearSelection();
        } else {
          let ind = temp.index;
          this.sconForm.scon_prod_list.splice(ind, 1);
          this.sconForm.scon_prod_list.splice(ind - 1, 0, temp);
          this.$refs.prodTabRef.clearSelection();
          this.$refs.prodTabRef.toggleRowSelection(temp);
        }
      }
      //重新计算排序字段
      this.sconProdSort();
    },
    // 下移
    moveDown() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
        this.$refs.prodTabRef.clearSelection();
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
        this.$refs.prodTabRef.clearSelection();
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.index === this.sconForm.scon_prod_list.length - 1) {
          this.$message.warning('已经是最后一条');
          this.$refs.prodTabRef.clearSelection();
        } else {
          let ind = temp.index;
          this.sconForm.scon_prod_list.splice(ind, 1);
          this.sconForm.scon_prod_list.splice(ind + 1, 0, temp);
          this.$refs.prodTabRef.clearSelection();
          this.$refs.prodTabRef.toggleRowSelection(temp);
        }
      }
      //重新计算排序字段
      this.sconProdSort();
    },
    //重新计算排序字段
    sconProdSort() {
      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        this.sconForm.scon_prod_list[i].prod_sort = i + 1;
      }
    },
    //成品复制按钮
    copySconProd() {
      if (this.selectionsList.length === 0) return this.$message.warning('请至少选择一条数据!');
      let copyProdList = cloneDeep(this.selectionsList);
      copyProdList.forEach(item => {
        item.scon_prod_id = -1;
        item.prod_id = null;
      });
      this.sconForm.scon_prod_list = this.sconForm.scon_prod_list.concat(copyProdList);
    },
    //销售成品子表同步成品按钮（从成品信息里从新获取数据）
    podrSync() {
      post(sconAPI.getPodrSync, { scon_prod_list: this.sconForm.scon_prod_list })
        .then(res => {
          if (res.data.code === 0) {
            let newscon_prod_list = res.data.data.scon_prod_list;
            // 替换新值
            for (let i = 0; i < newscon_prod_list.length; i++) {
              this.$set(this.sconForm.scon_prod_list[i], 'prod_boxl', newscon_prod_list[i].prod_boxl);
              this.$set(this.sconForm.scon_prod_list[i], 'prod_boxw', newscon_prod_list[i].prod_boxw);
              this.$set(this.sconForm.scon_prod_list[i], 'prod_boxh', newscon_prod_list[i].prod_boxh);
              //计算外箱体积
              let prod_vol = (newscon_prod_list[i].prod_boxl / 100) * (newscon_prod_list[i].prod_boxw / 100) * (newscon_prod_list[i].prod_boxh / 100);
              this.$set(this.sconForm.scon_prod_list[i], 'prod_vol', this.helper.calcPriceT(prod_vol));
              this.totalVolumeChange(i);
            }
            return this.$message.success('成功！');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.log(err));
    },
    // 计算总毛重
    totalGrossWeightChange(val) {
      let calcNum = new BigNumber(this.sconForm.scon_prod_list[val].prod_box_num);
      calcNum = calcNum.times(this.sconForm.scon_prod_list[val].prod_gweight).toNumber();
      this.sconForm.scon_prod_list[val].total_gross_weight = this.helper.reservedDigits(calcNum);
    },
    // 计算总净重
    totalNetWeight(val) {
      let calcNum = new BigNumber(this.sconForm.scon_prod_list[val].prod_box_num);
      calcNum = calcNum.times(this.sconForm.scon_prod_list[val].prod_nweight).toNumber();
      this.sconForm.scon_prod_list[val].total_net_weight = this.helper.reservedDigits(calcNum);
    },
    // 总体积
    totalVolumeChange(val) {
      this.$set(
        this.sconForm.scon_prod_list[val],
        'total_volume',
        new BigNumber(this.sconForm.scon_prod_list[val].prod_box_num).times(this.sconForm.scon_prod_list[val].prod_vol).toFixed(2)
      );
    },
    // 计算箱数
    prodBoxNum(val) {
      if (Number(this.sconForm.scon_prod_list[val].prod_num) % Number(this.sconForm.scon_prod_list[val].prod_qpc) !== 0) {
        this.$message.warning('箱数未能整除，已向上取整');
      }
      let temp = new BigNumber(this.sconForm.scon_prod_list[val].prod_num).div(this.sconForm.scon_prod_list[val].prod_qpc);
      if (temp.isFinite()) {
        this.sconForm.scon_prod_list[val].prod_box_num = temp.toFixed(0, 0);
      } else {
        this.sconForm.scon_prod_list[val].prod_box_num = null;
      }
      this.totalGrossWeightChange(val);
      this.totalNetWeight(val);
      this.totalVolumeChange(val);
      this.$emit('sconProdPriceChange');
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.sconForm.scon_prod_list[val1].prod_espec;
      } else if (val === 1) {
        this.textEnlargementString.str = this.sconForm.scon_prod_list[val1].prod_ename;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.sconForm.scon_prod_list[this.textEnlargementNum].prod_espec = val;
      } else if (this.textEnlargementFlag === 1) {
        this.sconForm.scon_prod_list[this.textEnlargementNum].prod_ename = val;
      }
    },
    getRowKeys(row) {
      return row.scon_prod_id;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .showRow {
  display: none;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-table__body-wrapper {
  z-index: 2;
}
::v-deep .el-form-item__label {
  display: none;
}
</style>
